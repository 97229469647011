import { PropsWithChildren, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '~/core/ui/Skeleton'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import { cn } from '~/core/ui/utils'

const SkeletonContainer = ({
  children,
  isLoadingMore,
  isFirstLoading,
  renderCustomSkeleton,
  className,
  classNameFirstLoading,
  classNameShowMore = '',
  useLoading = true,
  hasNextPage,
  fetchNextPage,
  showMoreLabel = 'Show more',
  defaultShowEndTextList = false
}: PropsWithChildren<{
  isLoadingMore?: boolean
  isFirstLoading: boolean
  renderCustomSkeleton?: ReactNode
  className?: string
  classNameFirstLoading?: string
  classNameShowMore?: string
  useLoading: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => Promise<any>
  showMoreLabel?: string
  defaultShowEndTextList?: boolean
}>) => {
  const { t } = useTranslation()
  const [isShowMoreClick, setIsShowMoreClick] = useState(defaultShowEndTextList)

  const renderDefaultSkeleton = () => {
    if (renderCustomSkeleton) {
      return (
        <div className={cn('space-y-4', classNameFirstLoading)}>
          {renderCustomSkeleton}
        </div>
      )
    }

    return (
      <div className={cn('space-y-4', classNameFirstLoading)}>
        {[1, 2, 3].map((item) => (
          <div key={`task-skeleton-${item}`} className="flex">
            <Skeleton className="mt-[6px] h-8 w-8 rounded-full" />
            <div className="ml-3 flex-1">
              <Skeleton className="mb-1 h-4 w-[50px]" />
              <Skeleton className="w-100 h-8" />
            </div>
          </div>
        ))}
      </div>
    )
  }

  if (isFirstLoading) return renderDefaultSkeleton()
  if (useLoading === false) return <>{children}</>
  return (
    <>
      {children}
      {hasNextPage && !isLoadingMore && (
        <div className={cn('mt-6 flex justify-center', classNameShowMore)}>
          <TextButton
            type="tertiary"
            underline={false}
            onClick={() => {
              setIsShowMoreClick(true)
              fetchNextPage && fetchNextPage()
            }}
            label={showMoreLabel}
          />
        </div>
      )}
      {isLoadingMore && (
        <div className={className}>{renderDefaultSkeleton()}</div>
      )}
      {!hasNextPage && !isLoadingMore && isShowMoreClick && (
        <div className={className}>
          <TypographyText className="mt-4 text-center text-base text-gray-500">
            {t('careers:thatAllJobs')}
          </TypographyText>
        </div>
      )}
    </>
  )
}

export default SkeletonContainer
